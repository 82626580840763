import { FastAverageColor } from "fast-average-color";
import fontColorContrast from "font-color-contrast";
import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  bigScreenBreakpoint, bigScreenDetailsBreakpoint,
  gameCoverEndpoint, gameCoverRatio,
  hostAddress, userGameCoverWidthSmall,
  userGameDetailsContainerWidth,
  userGameDetailsCoverWidth, userGameMinDetailsDiaryWidth
} from "../utils/constants";
import { UserGameType } from "../utils/types";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import DetailsModalFooter from "./details-modal-footer";
import GameOverlay from "./game-overlay";
import UserGameHeader from "./user-game-header";
import UserGamePlaythrough from "./user-game-playthrough";
import BackButton from "./utils/back-button";
import InteractiveIcon from "./utils/interactive-icon";

const UserGameDetails : FC<{game: UserGameType, clearModalData: () => void}> =
    ({ game, clearModalData }) => {

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenDetailsBreakpoint}px)` });
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
      const { variant } = React.useContext(VariantContext);

      const [isLight, setIsLight] =useState<boolean>(false);

      const { reviews, platinum, favourite, favouriteMusic, playthroughs, achieved } = game;
      const allPlaythroughs = playthroughs ?? [];

      const assessFontColor = async () => {
        const fac = new FastAverageColor();
        const color = await fac.getColorAsync(`${hostAddress}${gameCoverEndpoint}${game.game.id}`);
        const fontColor = fontColorContrast(color.hex, 0.8);
        setIsLight(fontColor === "#000000");
      };

      const completed = allPlaythroughs.filter((playthrough)=>
        playthrough.completed).length > 0;

      const childGame = allPlaythroughs.filter((playthrough)=>
        playthrough.type === "kid").length > 0;

      useEffect(() => {
        assessFontColor();
      }, []);

      return (
        <>
          <div
            className={`d-flex gap-${isMobile
              ? 2
              : 4} mb-0 ${!isBigScreen && "flex-wrap"} position-relative overflow-hidden`}>
            <CardBackground
              endpoint={gameCoverEndpoint}
              visible={true}
              id={game.game.id}
              zIndex={-10}/>
            <GameOverlay
              opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayDetailsOpacity}
              color={colors[variant.theme as keyof typeof colors].bgOverlay}
              zIndex={-5}
              visible={true}/>

            {isBigScreen ?
              <InteractiveIcon
                className={"m-1 text-danger position-absolute top-0 end-0"}
                iconName={"fas fa-circle-xmark"}
                onClick={clearModalData}/> :

              <div
                onClick={clearModalData}
                className={"ms-auto mt-3 me-3 mb-2"}>
                <BackButton text={"powrót"}/>
              </div>}

            <div
              className={`d-flex ${!isBigScreen && "p-3 w-100 border-top border-1 border-primary"}`}
              style={{
                minWidth: isBigScreen
                  ? userGameDetailsContainerWidth
                  : undefined,
                width: isBigScreen
                  ? userGameDetailsContainerWidth
                  : undefined
              }}>
              <Cover
                ratio={gameCoverRatio}
                endpoint={gameCoverEndpoint}
                id={game.game.id}
                width={isMobile
                  ? userGameCoverWidthSmall
                  : userGameDetailsCoverWidth}/>
              <UserGameHeader
                isMobile={isMobile}
                lightCover={!isLight}
                stroke
                tags={game.tags}
                hideLastHr
                game={game.game}/>

            </div>
            <div className={"ps-3 pe-3 w-100"}>
              <div
                style={{
                  minWidth: !isMobile
                    ? userGameMinDetailsDiaryWidth
                    : undefined
                }}
                className={`${!isLight
                  ? "text-shadow-black"
                  : "text-black"} mb-2 text-center mt-2`}>
                Dziennik
              </div>
              <div>
                {game.playthroughs.map((playthrough) =>
                  <div
                    className={"mt-2 mb-2"}
                    key={`playthrough-${playthrough.id}`}>
                    <UserGamePlaythrough
                      playthrough={playthrough}
                      playthroughCount={game.playthroughs.length}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <DetailsModalFooter
            reviews={reviews}
            achieved={achieved}
            favouriteMusic={favouriteMusic}
            favourite={favourite}
            platinum={platinum}
            completed={completed}
            childhood={childGame}
            isGame={true}/>
        </>);
    };

export default UserGameDetails;