import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

const BackButton: FC<{route?: string,
  text: string}>
    =  ({ route, text }) => {
      const navigate = useNavigate();

      return (
        <span
          onClick={() => 
            route && navigate(route)
          }
          className={"btn btn-danger"}>
          <i
            className={"me-2 fa-solid fa-arrow-rotate-left"}
          />
          <span></span>
          {text}
        </span>
      );
    };

export default BackButton;