import React, { FC } from "react";

import CustomTooltip from "./utils/custom-tooltip";

export const BadgesPanel : FC<{favourite?: boolean, platinum?: boolean, childhood?: boolean,
    completed?: boolean, achieved?: boolean, favouriteMusic?: boolean, isGame: boolean }> =
    ({   platinum, favourite, childhood, favouriteMusic, achieved, completed, isGame }) => {

      const completedMessage = isGame
        ? "ukończona"
        : "obejrzany";

      const childhoodMessage = isGame
        ? "gra dzieciństwa"
        : "film dzieciństwa";

      return (
        <div className={"d-flex"}>
          <div className={"d-flex align-items-center gap-2"}>
            {favouriteMusic && <CustomTooltip text={"ulubiona muzyka"}>
              <span
                style={{ color: "#a4a5ff",
                  marginRight: 3 }}
                className={"fas fa-music"}></span>
            </CustomTooltip>}
            {achieved && <CustomTooltip text={"wszystkie osiągnięcia"}>
              <span className={"fas fa-trophy text-warning"}></span>
            </CustomTooltip>}
            {platinum && <CustomTooltip text={"platynka"}>
              <span
                style={{ color: "#36ffff" }}
                className={"fas fa-trophy"}></span>
            </CustomTooltip>}
            {favourite && <CustomTooltip text={"ulubiona"}>
              <span className={"fas fa-heart text-danger"}></span>
            </CustomTooltip>}
            {childhood && <CustomTooltip text={childhoodMessage}>
              <span
                style={{ color: "#63ff99" }}
                className={"fas fa-baby-carriage"}></span>
            </CustomTooltip>}
            {completed &&  <CustomTooltip text={completedMessage}>
              <span className={"fas fa-circle-check"}></span>
            </CustomTooltip>}
          </div>
        </div>
      );
    };
