import React, { FC, useCallback, useEffect, useState } from "react";

import { filmCoverRatio } from "../utils/constants";
import { FilterFlagsType } from "../utils/types";
import CinemaListEvents from "./cinema-list-events";
import { Cover } from "./cover";


const CinemaListTimetable: FC<{film: any, flags: FilterFlagsType, isMobile: boolean,
  offset: number, setOffset: (offset:number) => void}>
    = ({ film, isMobile, flags, offset, setOffset }) => {

      const [currentDates, setCurrentDates] = useState<string[]>([]);

      const dates = Object.keys(film.dates);
      const datesCount = dates.length;
      const maxColumns = isMobile
        ? 2
        : 4;

      useEffect(() => {
        setOffset(0);
      }, [maxColumns]);

      useEffect(() => {
        setCurrentDates(dates.slice(offset, offset + maxColumns));
      }, [offset, maxColumns]);

      return (
        <div className={"d-flex w-100 justify-content-between gap-5"}>
          <>
            {Object.entries(film.dates).map(([date, events]: any) =>
              (
                currentDates.includes(date) && (
                  <div
                    style={{ userSelect: "none" }}
                    className={"w-100"}
                    key={film.id + date}>
                    <div className={"d-flex gap-2 align-items-center justify-content-between"}>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setOffset(Math.max(offset - 1, 0))}>
                        {date === currentDates[0] && date !== dates[0] && <span className={"fa fa-arrow-circle-left"}/>}
                      </div>
                      <div>
                        <span>{`${new Date(date).toLocaleString("pl-PL", { weekday: "short" })}`}</span>
                        <span>{` (${date.slice(5)})`}</span>
                      </div>

                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          setOffset(Math.min(offset + 1, datesCount - maxColumns))}>
                        {date === currentDates[maxColumns - 1]
                            && date !== dates[datesCount - 1]  &&<span className={"fa fa-arrow-circle-right"}/> }
                      </div>
                    </div>
                    <hr/>
                    <CinemaListEvents
                      isMobile={isMobile}
                      events={events}
                      flags={flags}/>
                  </div>)
              ))
            }
          </>
        </div>
      );
    };

export default CinemaListTimetable;