import React, { FC, useState } from "react";

import { colors } from "../utils/colors";
import { defaultVariant } from "../utils/constants";
import { ReviewType } from "../utils/types";
import UserReviewEntry from "./user-review-entry";


const UserReview : FC<{review: ReviewType}>  =
    ({ review }) => {
      const [variant ] = useState(defaultVariant);
      return (
        <div className={"bg-secondary m-3 mt-1 rounded-2 p-1"}>
          <div
            className={"p-3 pt-2 pb-2 w-100 d-flex flex-column scroll-secondary"}
            style={{ whiteSpace: "pre-wrap",
              fontSize: 14,
              color: colors[variant.theme as keyof typeof colors].lightText,
              textAlign: "justify" }}>
            {review.entries.map((entry, index) =>
              (
                <UserReviewEntry
                  reviewEntry={entry}
                  lastEntry={index === (review.entries.length - 1)}
                  key={`review-entry-${entry.id}`}/>
              ))}
          </div>
        </div>);
    };

export default UserReview;