import "./layout.scss";

import FancyText from "@carefully-coded/react-text-gradient";
import React, { useEffect, useState } from "react";
import MediaQuery from "react-responsive";
import { Link, Outlet, useLocation } from "react-router-dom";

import ToggleAction from "../components/utils/toggle-action";
import { Themes } from "../utils/colors";
import { defaultVariant } from "../utils/constants";
import { RoutePaths } from "../utils/routes";
import VariantContext from "./context/variantContext";
import NavigationIcon from "./navigation-icon";

const DarkTheme = React.lazy(() =>
  import("./themes/theme-dark"));

const GreyTheme = React.lazy(() =>
  import("./themes/theme-grey"));

const Layout = () => {
  const [variant, setVariant ] = useState(defaultVariant);
  const [animateHeader, setAnimateHeader ] = useState(false);
  const { theme, advancedBgs } = variant;

  const location = useLocation();

  /*  const [theme, setTheme] = useState(Themes.Dark);*/
  const [reloaded, setReloaded] = useState(true);

  useEffect(() => {
    setReloaded(false);
    const userGamesViewFlag = RoutePaths.USER_GAMES_SUB_ROUTE === location.pathname;
    const userFilmsViewFlag = RoutePaths.USER_FILMS_SUB_ROUTE === location.pathname
        || RoutePaths.CINEMA_ROUTE === location.pathname;

    if (userGamesViewFlag){
      setVariant({ ...variant,
        theme: Themes.Dark });
      if (!reloaded){
        window.location.reload();
      }
    }

    if (userFilmsViewFlag){
      setVariant({ ...variant,
        theme: Themes.Grey });
      if (!reloaded){
        window.location.reload();
      }
    }
  }, [location]);

  return (
    <>
      <React.Suspense
        key={theme}
        fallback={<></>}>
        {theme === Themes.Grey && <GreyTheme />}
        {theme === Themes.Dark && <DarkTheme />}
        <div className={"mb-5"}>
          <div
            style={{ zIndex: 100 }}
            className='ps-3 pe-3 d-flex text-white sticky-top
             justify-content-between bg-primary align-items-center'>
            <Link
              to={"/"}
              className={"w-100"}>
              <div className={"w-100"}>
                {animateHeader
                  ?    <FancyText
                    style={{ fontWeight: 1000,
                      cursor: "pointer" }}
                    className={"fs-3"}
                    gradient={{ from: "#ffffff",
                      to: "#ffffff",
                      type: "linear" }}
                    animateTo={{ from: "#F858E0",
                      to: "#00ffff" }}
                    animate
                    animateDuration={1500}
                  >
                    <div className={"d-flex align-items-center"}>
                      <span className={"fas fa-soap me-2 fs-5"}/>
                      StoryClicks
                    </div>
                  </FancyText>
                  :
                  <FancyText
                    style={{ fontWeight: 1000,
                      cursor: "pointer" }}
                    onMouseEnter={() =>
                      setAnimateHeader(true)}
                    gradient={{ from: "#ffffff",
                      to: "#ffffff",
                      type: "linear" }}
                    className={"fs-3"}
                  >
                    <div className={"d-flex align-items-center"}>
                      <span className={"fas fa-soap me-2 fs-5"}/>
                      StoryClicks
                    </div>
                  </FancyText>}
              </div>
            </Link>

            <MediaQuery query={"(max-width: 800px)"}>
              <div className={"d-flex justify-content-center bg-primary"}>
                <NavigationIcon
                  small
                  to={RoutePaths.USER_GAMES_SUB_ROUTE}
                  icon="gamepad"/>
                <NavigationIcon
                  small
                  to={RoutePaths.USER_FILMS_SUB_ROUTE}
                  icon="video"/>
                <NavigationIcon
                  small
                  to={RoutePaths.LISTS_ROUTE}
                  icon="layer-group"/>
                <NavigationIcon
                  small
                  to={RoutePaths.CINEMA_ROUTE}
                  icon="clapperboard"/>
              </div>
            </MediaQuery>

            <MediaQuery query="(min-width: 800px)">
              <div className={"w-100 d-flex justify-content-center bg-primary"}>
                <NavigationIcon
                  to={RoutePaths.USER_GAMES_SUB_ROUTE}
                  icon="gamepad"/>
                <NavigationIcon
                  to={RoutePaths.USER_FILMS_SUB_ROUTE}
                  icon="video"/>
                <NavigationIcon
                  to={RoutePaths.LISTS_ROUTE}
                  icon="layer-group"/>
                <NavigationIcon
                  to={RoutePaths.CINEMA_ROUTE}
                  icon="clapperboard"/>
              </div>
              <div
                className={"w-100 d-flex justify-content-end align-items-center"}>
                <ToggleAction
                  icon={"wand-magic-sparkles"}
                  value={advancedBgs}
                  action={(value)=>
                    setVariant({ ...variant,
                      advancedBgs: value })
                  }/>
              </div>
            </MediaQuery>


          </div>
          <VariantContext.Provider
            value={{ variant,
              setVariant }}>
            <Outlet/>
          </VariantContext.Provider>
        </div>
      </React.Suspense>
    </>
  );
};
export default Layout;