import React, { useCallback, useEffect, useState } from "react";
import Loading from "react-loading";
import { useMediaQuery } from "react-responsive";

import {
  bigScreenBreakpoint, bigScreenCinemaBreakpoint,
  cinemaListEndpoint,
  FilterFlagsTypeLabels,
  hostAddress,
} from "../utils/constants";
import { FilterFlagsType } from "../utils/types";
import CinemaListFilm from "./cinema-list-film";
import CommonLoading from "./utils/common-loading";

const defaultSettings : FilterFlagsType = {
  isWroclavia: true,
  isKorona: true,
  isBasic: true,
  is3d : true,
  is4dx : false,
  is2d : true,
  isImax: true,
  isDubbed: false,
  isScreenX : false,
  isVIP : false,
  isAtmos: true
};

const CinemaList  = () => {
  const [ccData, setCcData] = useState<any>([]);
  const [flags, setFlags] = useState<FilterFlagsType>(defaultSettings);
  const [covers, setCovers] = useState(false);
  const [filter, setFilter] = useState<string>("");
  const [offset, setOffset] = useState(0);

  const fetchCCData = useCallback(() => {
    fetch(`${hostAddress}${cinemaListEndpoint}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const arrayData = Object.values(data);
        const primarySorted : any = arrayData.sort((f1 : any) =>
          f1.seen
            ? -1
            : 1);

        const secondarySorted = primarySorted.sort = primarySorted.sort((f1 : any) =>
          f1.favourite
            ? -1
            : 1);

        setCcData(secondarySorted);
      });
  }, []);

  useEffect(() => {
    fetchCCData();
  }, []);

  const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenCinemaBreakpoint}px)` });

  return (
    <div className={"container"}>
      <div className={"mt-4 d-flex gap-2 justify-content-center flex-wrap"}>

        <input
          onChange=
            {(e) =>
              setFilter(e.target.value)}
          type={"text"}
          className={`w-${isBigScreen
            ? "auto"
            : "100"} text-center bg-secondary 
          border-0 text-body form-control`}/>
        <span
          style={{
            color: covers
              ? undefined
              : "#7c7c7c" }}
          onClick={() =>
            setCovers(!covers)}
          className={`btn btn-${covers
            ? "primary"
            : "secondary"}`}>
          <span className={"fa fa-image"}></span>
        </span>
        {Object.keys(flags).map((flag) =>
          <span
            style={{ minWidth: 80,
              color: flags[flag as keyof typeof flags]
                ? undefined
                : "#7c7c7c" }}
            key={`filter-${flag}`}
            onClick={() =>
              setFlags({
                ...flags,
                [flag]: !flags[flag as keyof typeof flags]
              })}
            className={`btn btn-${flags[flag as keyof typeof flags]
              ? "primary"
              : "secondary"}`}>
            {FilterFlagsTypeLabels[flag as keyof typeof flags]}
          </span>
        )}
      </div>

      <hr className={"mb-0"}/>
      {ccData.length === 0 ?
        <div className={"d-flex mt-0 w-100 justify-content-center"}><CommonLoading/></div> :
        <div className={"mt-3"}>
          {
            ccData.map((film: any) =>
              (
                film.name.toLowerCase().includes(filter.toLowerCase()) &&
                  <div key={film.name}>
                    <CinemaListFilm
                      offset={offset}
                      setOffset={setOffset}
                      flags={flags}
                      covers={covers}
                      film={film}/>
                    <hr/>
                  </div>
              ))
          }
        </div>}
    </div>);
};

export default CinemaList;