import React, { FC, useEffect, useState } from "react";

import {
  CustomListSort,
  CustomListsSortNames
} from "../utils/constants";
import { ListItemType, ListType } from "../utils/types";

export const UserCustomListFilters: FC<{listData: ListType,
    isMobile: boolean, setItems: (items: ListItemType[]) => void}>
    =  ({ listData, isMobile, setItems }) => {

      const [sort, setSort ]
        = useState(CustomListSort.EntryDate);

      const [filter, setFilter ]
      = useState<string>();

      const [reverseSort, setReverseSort ]
        = useState(true);

      useEffect(() => {
        let defaultSort = CustomListSort.EntryDate;
        if(listData?.showPriority){
          defaultSort = CustomListSort.Priority;
        }else if(listData?.showValue){
          defaultSort = CustomListSort.Value;
        }
        setSort(defaultSort);
      }, [listData]);

      useEffect(()=>{
        const items = [...(listData?.items ?? []) ];
        const itemsFiltered = items.filter(item =>
          item.game?.name.toLowerCase()
            .includes(filter?.toLowerCase() ?? "") ||
          item.film?.titlesJoined.toLowerCase()
            .includes(filter?.toLowerCase() ?? ""));

        if(sort !== CustomListSort.EntryDate){
          itemsFiltered.sort((item, item2) : number => {
            if(sort === CustomListSort.ReleaseDate){
              const firstDate =
                Date.parse(reformatDate(item?.game?.released ?? item?.film?.yearDate ?? ""));
              const secondDate =
                Date.parse(reformatDate(item2?.game?.released ?? item2?.film?.yearDate ?? ""));

              return firstDate > secondDate
                ? 1
                : -1 ;
            }else if (sort === CustomListSort.Name){
              const item1title = item?.game?.name ?? item?.film?.title ?? "";
              const item2title = item2?.game?.name ?? item2?.film?.title ?? "";
              return item1title.localeCompare(item2title);
            }
            else if (sort === CustomListSort.Value){
              return item?.value > item2?.value
                ? 1
                : -1 ;
            }
            else if (sort === CustomListSort.Priority){
              return item2?.priority > item?.priority
                ? 1
                : -1 ;
            }
            else{
              return 1;
            }
          });
        }
      

        if(reverseSort){
          itemsFiltered.reverse();
        }

        setItems(itemsFiltered);
      }, [listData, filter, sort, reverseSort]);

      const reformatDate = (date: string) => {
        const parts = date.split("-");
        return `${parts[2]}-${parts[1]}-${parts[0]}`;
      };

      const inputHeight = isMobile
        ? 40
        : 50;

      return (
        <div
          className={"d-flex justify-content-center gap-4 p-2 flex-wrap"}>
          <div
            className={`d-flex ${isMobile && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Szukaj"}
              type={"text"}
              style={{ maxWidth: 90 }}
              className={`rounded-end-0 w-auto text-center bg-primary
              border-0 text-body form-control`}/>
            <input
              onChange=
                {(e)=>
                  setFilter(e.target.value)}
              type={"text"}
              className={`w-${!isMobile
                ? "auto"
                : "100"} text-center bg-secondary 
          border-0 rounded-start-0 text-body form-control ${!isMobile && "form-control-lg"}`}/>
          </div>
          <div
            className={`d-flex ${isMobile && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Sortuj"}
              type={"text"}
              style={{ maxWidth: 90 }}
              className={`rounded-end-0 w-auto text-center bg-primary
              border-0 text-body form-control`}/>
            <select
              value={sort}
              onChange=
                {(e)=>
                  setSort(e.target.value as CustomListSort)}
              className={`w-${!isMobile
                ? "auto"
                : "100"} rounded-0 bg-secondary border-0 text-body form-select`}>
              {Object.keys(CustomListSort).map((key)=> {
                const sortType = CustomListSort[key as keyof typeof CustomListSort];
                return (
                  <option
                    key={`sort-${key}-option`}
                    value={sortType}>
                    {CustomListsSortNames[sortType as keyof typeof CustomListsSortNames]}
                  </option>
                );
              })
              }
            </select>

            <div
              onClick={()=>
                setReverseSort(!reverseSort)}
              className={"btn rounded-start-0 btn-secondary d-flex align-items-center"}>
              <i
                className={`fa-solid fa-${reverseSort
                  ? "arrow-up-wide-short"
                  : "arrow-down-wide-short"}`}></i>
            </div>
          </div>
        </div>
      );
    };
