import React, { FC, useState } from "react";

import { ReviewType } from "../utils/types";
import { BadgesPanel } from "./badges-panel";
import ReviewBadge from "./review-badge";
import UserReview from "./user-review";


const DetailsModalFooter : FC<{reviews: ReviewType[], achieved?: boolean,
    favouriteMusic?: boolean, favourite?: boolean,
    platinum?: boolean, completed?: boolean,
    childhood?: boolean, isGame: boolean }> =
    ({ reviews, platinum, favourite, favouriteMusic,
      achieved, childhood, isGame, completed  }) => {
      const [activeReview, setActiveReview] = useState<number|undefined>(reviews.length > 0
        ? 0
        : undefined);

      return (
        <>
          <div className={"d-flex justify-content-between align-items-center pt-0 pb-0 p-2 mt-2 mb-2"}>

            <div className={"d-flex"}>
              {reviews.map((review, index) =>
                (
                  <ReviewBadge
                    index={index}
                    setActiveReview={setActiveReview}
                    key={review.id}
                    review={review}
                    reviewCount={reviews.length}
                    active={activeReview === index}
                    showDate/>
                ))}

              {reviews?.length === 0 &&
              <span className={"ms-2 p-2 rounded-2 bg-secondary"}>
                Brak opinii
              </span> }
            </div>
            <div className={"me-2 p-0"}>
              <BadgesPanel
                achieved={achieved}
                favouriteMusic={favouriteMusic}
                favourite={favourite}
                platinum={platinum}
                completed={completed}
                childhood={childhood}
                isGame={isGame}/>
            </div>
          </div>

          {activeReview !== undefined && (reviews[activeReview].entries.length > 0) &&
          <UserReview review={reviews[activeReview]}/>
          }
        </>);
    };

export default DetailsModalFooter;