import React, { FC, useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { bigScreenBreakpoint, bigScreenCinemaBreakpoint, filmCoverRatio } from "../utils/constants";
import { FilterFlagsType } from "../utils/types";
import CinemaListTimetable from "./cinema-list-timetable";
import { Cover } from "./cover";


const CinemaListFilm: FC<{offset: number, setOffset: (offset:number) => void, film: any,
    covers: boolean, flags: FilterFlagsType}>  = ({ film, covers, flags, offset, setOffset }) => {

      const [open, setOpen] = useState(false);

      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenCinemaBreakpoint}px)` });

      return (
        <div
          className={`d-flex justify-content-between ${isMobile
            ? "flex-wrap"
            : "gap-5"} ${isMobile && open && "gap-4"}`}>
          <div
            className={`${isMobile && "d-flex w-100 justify-content justify-content-between"}`}
            style={{ cursor: "pointer",
              minWidth: 200 }}
            onClick={() =>
              setOpen(!open)}>
            <div
              style={{
                color: open
                  ? undefined
                  : "#7c7c7c"
              }}
              className={`${isMobile && "w-100"} rounded-2 bg-${open
                ? "primary"
                : "secondary"} p-2`}>
              <span className={"fw-bold"}>{film.name}</span>
              <br/>
              <span style={{ userSelect: "none" }}>
                {`${film.length} min`}
              </span>
              <br/>
              <div className={"d-flex gap-2 mt-1"}>
                {film.favourite && <span className={"fa fa-heart text-danger"}/>}
                {film.seen && <span className={"fa fa-eye text-success"}/>}
              </div>


            </div>
            {covers &&
              <Cover
                overrideEndpoint={film.posterLink}
                width={150}
                ratio={filmCoverRatio}/>}
          </div>

          {open
            ? <CinemaListTimetable
              offset={offset}
              setOffset={setOffset}
              film={film}
              isMobile={isMobile}
              flags={flags}/>
            : <div></div>}

        </div>);
    };

export default CinemaListFilm;