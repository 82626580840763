import React, { FC } from "react";

import { FilterFlagsType } from "../utils/types";


const CinemaListFilm: FC<{events: any, flags: FilterFlagsType, isMobile: boolean}>  = ({ events, flags, isMobile }) => {
  const negated = Object.keys(flags).filter((flag) => 
    !flags[flag as keyof typeof flags]);

  const filteredEvents = events.filter((event : any) => {
    return !negated.some((negatedKey) => 
      event[negatedKey]);
  });

  const koronaColor = "#8c4800";
  const wroclaviaColor = "#6000c3";
  const noColor = "#9c0020";

  return (
    <>
      {filteredEvents.length === 0 && <div
        className={"mt-3 rounded-2 p-3"}
        style={{
          backgroundColor: noColor,
          minWidth: 150 }}>
        <span>brak seansów</span>
      </div>}
      {
        filteredEvents.map((event : any, id: number) =>
          (
            <div
              className={"mt-3 rounded-2 p-3"}
              style={{ cursor: "pointer",
                minWidth: 150,
                backgroundColor: event.isWroclavia
                  ? wroclaviaColor
                  : koronaColor }}
              onClick={()=> {window.location.href = event.link;}}
              key={event.id}>
              <span>{`${event.time} - ${event.endTime}`}</span>
              <br/>
              <span>
                {event.isBasic && "Basic  "}
                {event.isDubbed && "Dub  "}
                {event.isImax && "IMAX  "}
                {event.isAtmos && "Atmos  "}
                {event.isVIP && "VIP  "}
                {event.is3D && "3D  "}
                {event.isScreenX && "ScreenX  "}
                {event.is4dx && "4Dx  "}
              </span>
            </div>
          ))
      }
    </>
  );
};

export default CinemaListFilm;