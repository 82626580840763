import React, { FC, PropsWithChildren } from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

import {
  bigScreenBreakpoint,
  detailsModalWidth,
} from "../utils/constants";

export const DetailsModal :
    FC<PropsWithChildren<{clearModalData: () => void}>> =
    ({  clearModalData, children }) => {

      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

      return (
        <Modal
          style={{
            overlay: {
              overflowY: "hidden",
              position: "fixed",
              zIndex: 105,
              backgroundColor: "rgba(0,0,0, 0.9)"
            },
            content: {
              maxWidth: detailsModalWidth,
              margin: isMobile
                ? 0
                : undefined,
              minHeight: isMobile
                ? "100%"
                : undefined,
              maxHeight: isMobile
                ? "100%"
                : "90%",
              outline: "0",
              borderRadius: isMobile
                ? undefined
                : "6px",
            }
          }}
          onRequestClose={clearModalData}
          className={`p-0 bg-primary position-absolute overflow-auto 
          ${!isMobile
          ? "scroll-secondary start-50 top-50 translate-middle"
          : "top-50 translate-middle-y hide-scrollbar"}`}
          isOpen={true}
          shouldCloseOnOverlayClick={true}
        >
          {children}
        </Modal>);
    };